<template>
  <div class="step-content">
    <v-card 
      outlined
      color="transparent"
      class="checklist text-left mx-n4 my-10"
    >
      <v-alert 
        :color="late ? 'error darken-1' : 'secondary'"
        transition="expand-transition"
        :icon="icons.calendar"
        class="deadline body-1 rounded-b-0 pa-4 mb-0"
      >
        <span v-if="deadline==null">
          Carregando...
        </span>
        <span v-else>
          <b>Prazo: até {{ deadline | simpleDate }}</b> ({{ deadline | timeTill }})
        </span>
      </v-alert>
        <!-- v-if="deadline==null" -->
      <v-progress-linear
        :active="loading"
        absolute
        indeterminate
        color="secondary"
        class="mb-0"
      />
        <!-- v-else -->
      <v-list 
        two-line
        color="transparent"
        class="feedbacks py-0 mt-0"
      >
        <v-list-item
          class="item"
          @click="toggleView('profile')"
        >
          <v-list-item-content>
            <v-list-item-title
              class="item-title"
            >
              Dados do Veículo
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action
            class="item-action"
          >
            <v-btn 
              v-if="step.profile<1"
              text
              color="primary"
              class="mr-n2"
              @click="toggleView('profile')"
            >
              Preencher
            </v-btn>
            <v-btn 
              v-else
              icon
              color="primary"
              @click.stop="toggleView('profile')"
            >
              <v-icon>{{ icons.check }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          v-for="(item, i) in step.envelopes.items"
          :key="i"
          class="item"
        >
          <v-list-item-content>
            <v-list-item-title
              class="item-title"
            >
              {{ i | envelopeTitle }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action
            class="item-action"
          >
            <v-btn 
              v-if="i=='driver'&&pendingEnvelope(item.status)"
              :text="step.profile==1&&pendingEnvelope(item.status)"
              :icon="step.profile<1||!pendingEnvelope(item.status)"
              :loading="loading"
              :disabled="step.profile<1"
              :color="step.profile<1 ? 'grey' : 'primary'"
              :class="{ 'mr-n2': step.profile==1&&pendingEnvelope(item.status) }"
              @click.stop="openEnvelope(item.url)"
            >
              <v-icon v-if="step.profile<1">
                {{ icons.lock }}
              </v-icon>
              <span v-else>
                Assinar
              </span>
            </v-btn>
            <v-btn 
              v-else-if="i=='owner'&&pendingEnvelope(item.status)"
              icon
              color="primary"
              class=""
              disabled
            >
              <v-icon v-if="item.status=='sent'">
                {{ icons.clock }}
              </v-icon>
              <v-icon v-else>
                {{ icons.lock }}
              </v-icon>
            </v-btn>
            <v-btn 
              v-else
              icon
              color="primary"
              :loading="loading"
              @click.stop="if (item.hasOwnAttribute('url')) openEnvelope(item.url)"
            >
              <v-icon>{{ icons.check }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-alert 
        v-if="showHint"
        text
        color="warning"
        class="text-body-2 font-weight-medium mb-0"
      >
        O proprietário do veículo deve assinar o documento que enviamos para o email {{ user.profile.vehicle.ownerEmail }}.
      </v-alert>
    </v-card>

    <slot name="quick-access" />

    <v-btn 
      text
      small
      class="help-btn"
      color="grey"
      @click="toggleHelp(true, 'signup')"
    >
      <v-icon
        small
        class="mr-4"
      >
        {{ icons.help }}
      </v-icon>
      Precisa de ajuda?
    </v-btn>

  </div>
</template>

<style>

  .step-content .checklist {
    max-width: initial;
    border-color: var(--v-grey-darken3) !important;
  }

  .step-content .item-feedback {
    line-height: 1.4;
    text-overflow: initial;
    white-space: initial;
  }

  .step-content .checklist .deadline {
    margin: -1px;
  }

  .step-content .checklist .item:not(:last-child) {
    border-bottom: 1px solid var(--v-grey-darken3) !important;
  }

</style>

<script>

  // Icons
  import { mdiHelpCircleOutline, mdiCalendarAlert, mdiCheck, mdiLock, mdiClockOutline } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { sync, get } from 'vuex-pathify'
  import _ from 'lodash';
  const moment = require('moment');

  // import { Plugins } from '@capacitor/core';
  // const { Browser } = Plugins;
  import { Browser } from '@capacitor/browser';

  export default {
    name: 'Step3',

    props: {
      step: {
        type: Object,
        default: () => {}
      },
      appURL: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        help: mdiHelpCircleOutline,
        calendar: mdiCalendarAlert,
        check: mdiCheck,
        lock: mdiLock,
        clock: mdiClockOutline
      },
      envelopeView: {
        toggle: false,
        selected: null,
      },
      loading: false,
      updated: false,
      deadline: null
    }),

    computed: {
      profileView: sync('app/views@profile'),
      user: sync('user'),
      status: sync('user/status'),
      toast: sync('app/toast'),
      help: sync('app/views@help'),
      help_sections: sync('help/sections'),

      stepName () {
        return this.$options.name.toLowerCase();
      },

      showHint () {
        const envelope = this.step.envelopes.items;
        return !this.pendingEnvelope(envelope.driver.status)&&this.pendingEnvelope(envelope.owner.status);
      },

      late () {
        return moment().isSameOrAfter(this.deadline);
      }
    },

    watch: {
      step: {
        immediate: true,
        deep: true,
        handler (step) {
          if (step.profile==1&&!this.updated&&!this.loading) {
            this.getSignerStatus();
          }
        }
      },
      user: {
        immediate: true,
        deep: true,
        handler (user) {
          if (!!user.statusChange) this.deadline = moment.utc(user.statusChange).local().add(3, 'days').endOf('day');
        }
      }
    },

    filters: {
      envelopeTitle (role) {
        const roles = {
          'driver': 'Sua assinatura',
          'owner': 'Proprietário',
        }
        return roles[role];
      },
      simpleDate (date) {
        return moment(date).format('DD/MM');
      },
      timeTill (date) {
        const today = moment().endOf('day');
        const left = moment(date).diff(today, 'd');
        return left >= 0 ? moment(today).to(date, true) : 'atrasado';
      }
    },

    methods: {
      ...services,  

      actionCalled () {
        // if (this.completion==100) {
        //   this.toggleConfirm(true);
        // }
      },

      updateView () {
        if (this.step.profile==1) {
          this.updated = false;
          this.getSignerStatus();
        }
      },

      pendingEnvelope (status) {
        return status!='completed';
      },

      async openEnvelope (url) {
        // open envelope URL
        console.log('### open browser...', url);
        await Browser.open({ url: url });
      },

      getSignerStatus () {
        this.loading = true;

        const token = this.user.auth.token;
        const cpf = this.rawCPF(this.user.cpf);

        this.$api.get('/signerdoc/'+cpf+'/'+token+'/?format=json')
          .then(response => {
            console.log('getSignerStatus => ',response);
            if(response.data.retorno==200){
              let data = response.data.data.replace(/'/g, '"');
              data = JSON.parse(data);
              let envelopes = _.keyBy(data.signers_status, item => {
                return item.role.toLowerCase();
              });
              envelopes.driver.url = data.url_signer.url;

              this.step.envelopes.items = envelopes;

              // this.updateDocsCompletion();

              // this.deadline = moment.utc(response.data.dt_Status).local().add(3, 'days').endOf('day');

              this.trackEvent('signup/'+this.stepName, 'get envelopes status ok');
            }else if(response.data.retorno==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              this.toggleToast(
                true,
                'Não foi possível carregar sua avaliação.',
                5000,
                false,
              );
              this.trackEvent('signup/'+this.stepName, 'get envelopes status failed');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },

    created () {
    },

    mounted () {
      Browser.addListener("browserFinished", (info) => {
        console.log('### browser closed', info);
        this.getSignerStatus();
      })
    },

    directives: {
    },

  }
</script>
